<template>
  <div class="d-flex d-col vh100 w100">
    <TopToolbar />
    <div class="d-flex grow w100">
      <Sidebar />
      <router-view class="grow"></router-view>
    </div>
    <Footer />
  </div>
</template>

<script>
import TopToolbar from "@/components/Toolbars/TopToolbar.vue";
import Sidebar from "@/components/Toolbars/Sidebar";
import Footer from "@/components/Toolbars/Footer.vue";

export default {
  name: "Dashboard",
  components: {
    TopToolbar,
    Sidebar,
    Footer,
  },
};
</script>

